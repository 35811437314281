/* You can add global styles to this file, and also import other style files */

//@import "~font-awesome/css/font-awesome.css";

$color-pink: #cf2b58;
$color-dark-pink: #b22a50;
$color-gray: #333;
$default-background-color: #ffffff;
$color-dark-blue: #000;
$color-light-green: #bedcc4;
$light-blue: #008BF9;

$spacer: 2rem;

$theme-colors: (
    "primary": $light-blue,
    "secondary": $color-dark-blue,
    "danger": $color-dark-pink
);


@import "bootstrap/scss/bootstrap";


@font-face {
    font-family: 'Merriweather Sans', sans-serif;
}

* {
    color: $color-gray
}

body {
    background-color: $default-background-color;
    font-family: 'Merriweather Sans', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Merriweather Sans', sans-serif;
    color: $color-dark-blue
}

h1 {
    text-align: center;
    width: 100%;
    font-weight: 900;
    font-size: 5em;
}

h2 {
    font-size: 3em;
}

.purple_border {
    border: 5px solid $color-pink;
    height: 140px;
    width: 140px;
}

.light-green-2 {
    background-color: $color-light-green;
}

.navbar {
    margin-bottom: 0px;
    z-index: 999;
}

.navbar-default {
    background: #ffffff;
    // border: 0;
}

.container-fluid>.navbar-header {
    margin-left: 0;
    margin-right: 0;
}

.navbar-brand {
    .brand {
        color: $color-pink;
    }
}

.home-partial {
    padding-top: 50px;
    padding-bottom: 30px;
    border-bottom: 10px solid $color-dark-pink;
}

.home-bg {
    padding-left: 0;
    height: 400px;

    .title {
        height: 200px;

        h1 {
            position: absolute;
            top: 25%;
        }

        p {
            position: absolute;
            top: 5%;
            font-weight: bold;
        }
    }

    &:before {
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        padding: 0 !important;
        background: url('/images/shopping_header.jpg') no-repeat top center fixed;
        opacity: 0.45;
        width: 100%;
        height: 400px;
        position: absolute;
        z-index: -1;
        content: "";
    }
}

.marketing {
    text-align: center;
}

.navbar-default {
    border-color: #fff !important;
    border: 0;
}

.navbar {
    border-bottom: 1px solid #e7e7e7 !important;
}

.home-mailing {
    padding-left: 0;
    height: 700px;

    &:before {
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background: url('/images/girls_clothes_shops.jpg') no-repeat top center fixed;
        opacity: 0.45;
        width: 100%;
        margin-top: -50px;
        height: 695px;
        position: absolute;
        z-index: -1;
        content: "";
    }
}

.about-bg {
    padding-left: 0;

    &:before {
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        padding: 0 !important;
        background: url('/images/about-bg.jpg') no-repeat top center fixed;
        opacity: 0.45;
        width: 100%;
        height: 400px;
        position: absolute;
        z-index: -1;
        content: "";
    }
}

.login-bg {
    padding-left: 0;

    &:before {
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        padding: 0 !important;
        background: url('/images/login-bg.jpg') no-repeat top center fixed;
        opacity: 0.45;
        width: 100%;
        height: 400px;
        position: absolute;
        z-index: -1;
        content: "";
    }
}

nav.sticky.fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
}

.thick-underline {
    border-bottom: 2px solid #000;
    margin-bottom: 20px;
}

.row .thick-underline {
    width: 66.66666667%;
    margin-left: auto;
    margin-right: auto;
}

.centered-text {
    width: 80%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 10px;
}

.dresses {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding: 0 !important;
    background: url('/images/dresses-53319_1280.jpg') no-repeat top center fixed;
    opacity: 0.45;
    width: 100%;
    height: 200px;
    z-index: -1;
    content: "";
}

.orange-full-width-section {
    background-color: #F48E79;
    padding: 10px;

    h1 {
        color: #000000;
    }
}

img.product {
    max-width: 100%;
    max-height: 100%;
    border: 0;
}

.row {
    .cards-bg {
        background: url('/images/stylexplore_cards.png') no-repeat top center fixed;
    }
}

.row {
    padding-top: 20px;
    padding-bottom: 20px;

    @media (min-width: 768px) {
        p.lead {
            margin: 0 15%;
        }
    }
}

.homepage-row-gray {
    background-color: #dadada;
}

.dress {
    padding-top: 20px;
}

img.responsive {
    max-width: 100%;
    margin: 0 auto;

    &.chill {
        width: 75%;
        max-width: 450px;
    }
}

.stylist-text-block {
    display: table;
    background-color: #e9e9e9;
    height: 200px;
    text-align: left;
    padding: 20px;
}

.stylist-text-content {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}

.centered-block-text {
    width: 45%;
}

.light-blue {
    background-color: #D2EAF2;
}

.light-green {
    background-color: #E2EDCF;
}

#beauty .container .centered-row {
    padding: 20px;
}

#homepage {
    #banners {
        height: 150px;
        background-color: navy;
        margin: 0 0 20px 0;

        .banner-text {
            margin: 20px 0 0 0;
            color: #ffffff;
        }
    }
}

#looks {
    margin: 20px;
}

img {
    &.small {
        height: 166px;
    }

    &.medium {
        height: 400px;
    }
}

#registration {
    flex: 1 0;
    padding-left: 60px;
}

.sidebar {
    max-width: 400px;
    flex: 0 0 400px;
}

.fullwidth {
    width: 100%;
}

.form-control {
    background: #F3F3F3;
}

.btn-success {
    background: $light-blue;
    border-color: $light-blue;
    border-radius: 10px;
}

.switch {
    width: 138px;
    height: 36px;
    left: 516px;
    top: 384px;
    border-radius: 10px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

form-validator div {
    color: #E82C0C;
    margin: 6px 0;
}

label.required:after {
    color: #d00;
    content: "*";
    margin-left: 2px;
}

.arrow-back {
    padding-top: 5px;
    padding-left: 5px;
    padding-right: 5px;
}

.popover-body .navbar-nav .nav-link {
    font-size: 1rem;
}

.private-group {
    background-color: #C0A8FF;
}